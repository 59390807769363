import React, { useEffect, useState } from "react"

import Moment from "react-moment"
import { Form, Modal, Offcanvas, OverlayTrigger, Popover } from "react-bootstrap"

import { LineChart, PieChart } from "react-chartkick"
import "chartkick/chart.js"

import classNames from "classnames"

import { IAuthor, IQuote, IQuoteAuthor } from "@/interfaces/Quote.interface"
import { ShareModal } from "./components/ShareModal"

import authors from "./data/authors.json"
import quotes from "./data/quotes.json"

import { resolveQuoteAuthors } from "./util/util"

function getAuthorQuotes(authorId: number) {
	return quotes.filter((q) => q.authors.some((a) => a.authorId === authorId))
}

function AuthorAvatar({ className, authorId, size }: { className?: string; authorId: number; size: number }) {
	return <img className={classNames(`tw-min-w-[${size}px] tw-min-h-[${size}px] tw-rounded-full`, className)} alt="" width={size} height={size} src={"/avatars/" + authorId + ".webp"} onError={(e) => ((e.target as HTMLImageElement).src = "/avatars/default/" + ((authorId * 1) % 5) + ".png")} />
}

function AuthorFilter({ author, isSelected, addSelectedAuthor, removeSelectedAuthor }: { author: IAuthor; isSelected: boolean; addSelectedAuthor: (authorId: number) => void; removeSelectedAuthor: (authorId: number) => void }) {
	const authorQuotes = getAuthorQuotes(author.authorId)

	return (
		<div
			className={classNames("tw-flex tw-items-center tw-p-1 tw-mt-1 tw-rounded tw-cursor-pointer hover:tw-bg-[rgba(256,256,256,0.05)]", {
				"tw-bg-[rgba(256,256,256,0.075)]": isSelected,
				"hover:tw-bg-[rgba(256,256,256,0.1)]": isSelected
			})}
			onClick={() => (!isSelected ? addSelectedAuthor(author.authorId) : removeSelectedAuthor(author.authorId))}
		>
			<AuthorAvatar className="tw-me-2" authorId={author.authorId} size={50} />
			<div>
				<h1 className="tw-block tw-font-bold">{author.name}</h1>
				<span>Цитати: {authorQuotes.length}</span>
			</div>
		</div>
	)
}

function AuthorPopover({ author }: { author: IAuthor }) {
	const authorQuotes = getAuthorQuotes(author.authorId)
	const aliases = Array.from(
		new Set(
			authorQuotes
				.map((q) => {
					return (q.authors.find((a) => a.authorId === author.authorId) as IQuoteAuthor).creditedAs
				})
				.filter((a) => a)
				.map((a: string) => {
					return a
						.trim()
						.split(",")[0]
						.split(/ [a-z]+ing /)[0]
						.split(" to ")[0]
						.split(" out of context ")[0]
						.split(/ v(uv)? /)[0]
						.split(/ s(us)? /)[0]
						.split(/ [a-z]+ki /)[0]
						.split(/ [a-z]+sht /)[0]
						.split(" kum ")[0]
						.split(" dokato ")[0]
						.split(" kogato ")[0]
						.split(" sled ")[0]
						.split(" izvun ")[0]
						.split(" za ")[0]
						.split(/ в(ъв)? /)[0]
						.split(/ с(ъс)? /)[0]
						.split(/ [a-z]+ки /)[0]
						.split(/ [a-z]+щ /)[0]
						.split(" към ")[0]
						.split(" докато ")[0]
						.split(" когато ")[0]
						.split(" след ")[0]
						.split(" извън ")[0]
						.split(" за ")[0]
						.trim()
				})
				.map((a) => {
					const b = a.slice(0, 4).normalize()
					return b[0].toUpperCase() + b.slice(1) + a.slice(4)
				})
				.filter((a) => {
					return a.toLowerCase() !== author.name.toLowerCase().trim()
				})
				.sort()
		)
	)

	return (
		<Popover className="tw-p-4 tw-w-[300px] tw-rounded tw-bg-background-300">
			<div className="tw-flex tw-items-center">
				<AuthorAvatar className="tw-me-2" authorId={author.authorId} size={50} />
				<div>
					<h1 className="tw-block tw-font-bold">{author.name}</h1>
					<span>Цитати: {authorQuotes.length}</span>
				</div>
			</div>
			{aliases.length ? (
				<>
					<div className="tw-mt-3 tw-font-bold">Познат също като:</div>
					<div className="tw-text-sm tw-overflow-auto tw-pb-1">
						{aliases.map((a) => (
							<span className="tw-block tw-text-nowrap">{a}</span>
						))}
					</div>
				</>
			) : null}
		</Popover>
	)
}

function Quote({ quoteAuthors, quote }: { quoteAuthors: IAuthor[]; quote: IQuote }) {
	const Popover = (
		<div>
			<AuthorPopover author={quoteAuthors[0]} />
		</div>
	)

	return (
		<>
			<OverlayTrigger rootClose trigger="click" placement="right" offset={[15, 10]} overlay={Popover}>
				<div className="tw-relative tw-max-h-[50px]">
					<AuthorAvatar className="tw-me-3 tw-cursor-pointer" authorId={quoteAuthors[0].authorId} size={50} />
					{quote.starred ? <i className="fas fa-star tw-text-[gold] tw-text-md tw-absolute tw-bottom-0 tw-right-2" /> : <i className="" />}
				</div>
			</OverlayTrigger>

			<div>
				<div>
					<OverlayTrigger rootClose trigger="click" placement="right" offset={[30, 10]} overlay={Popover}>
						<span className="tw-font-bold tw-cursor-pointer hover:tw-underline">{quote.authors[0].creditedAs ?? quoteAuthors[0].name}</span>
					</OverlayTrigger>
					{quote.date ? (
						<span className="tw-text-gray-400 tw-text-sm tw-ms-2">
							<Moment format="MM/DD/YYYY" date={quote.date} />
						</span>
					) : null}
				</div>
				<span className="tw-whitespace-pre-line">{quote.content}</span>
			</div>
		</>
	)
}

function Dialogue({ quoteAuthors, quote }: { quoteAuthors: IAuthor[]; quote: IQuote }) {
	return (
		<>
			<div className="tw-relative tw-me-3 tw-min-w-[50px] tw-min-h-[50px] tw-max-h-[50px]">
				<AuthorAvatar className="tw-outline tw-outline-background-600 tw-absolute tw-z-10" authorId={quote.authors[0].authorId} size={35} />
				<AuthorAvatar className="tw-absolute tw-bottom-0 tw-right-0" authorId={quote.authors[1].authorId} size={35} />
				{quote.starred ? <i className="fas fa-star tw-text-[gold] tw-text-md tw-absolute -tw-bottom-1 -tw-right-2" /> : <i className="" />}
			</div>

			<div className="tw-flex tw-flex-col">
				{quote.content.map((c, i) => (
					<div key={i}>
						<span>
							<OverlayTrigger
								rootClose
								trigger="click"
								placement="right"
								offset={[30, 10]}
								overlay={
									<div>
										<AuthorPopover author={quoteAuthors[i]} />
									</div>
								}
							>
								<span className="tw-font-bold tw-me-1 tw-cursor-pointer hover:tw-underline">{quote.authors[i].creditedAs ?? quoteAuthors[i].name}:</span>
							</OverlayTrigger>
						</span>
						<span className="tw-whitespace-pre-line">{c}</span>
					</div>
				))}

				{quote.date ? (
					<span className="tw-text-gray-400 tw-text-sm tw-mt-1">
						<Moment format="MM/DD/YYYY" date={quote.date} />
					</span>
				) : null}
			</div>
		</>
	)
}

function QuoteWrapper({ quote, setShowSharing, setShareQuote }: { quote: IQuote; canvas: HTMLCanvasElement | null; setShowSharing: (val: boolean) => void; setShareQuote: (val: IQuote | null) => void }) {
	const quoteAuthors = resolveQuoteAuthors(quote)

	return (
		<div
			// id={encodeURIComponent(quote.content[0])}
			className="sm:tw-px-6 tw-py-3 tw-w-full tw-flex tw-rounded tw-group"
		>
			{quote.content.length > 1 ? <Dialogue quoteAuthors={quoteAuthors} quote={quote} /> : <Quote quoteAuthors={quoteAuthors} quote={quote} />}
			<button
				className="tw-h-fit tw-opacity-0 tw-text-gray-400 tw-text-xl tw-ms-auto tw-px-2 tw-py-1 hover:tw-text-gray-100 group-hover:tw-opacity-100"
				onClick={() => {
					setShareQuote(quote)
					setShowSharing(true)
				}}
			>
				<i className="fas fa-share" />
			</button>
		</div>
	)
}

function Container({ className, children }: { className?: string; children: React.ReactNode }) {
	return <div className={classNames("tw-px-3 tw-py-6 tw-rounded tw-shadow tw-bg-background-600 tw-container", className)}>{children}</div>
}

function App() {
	const [show, setShow] = useState(false)
	const [showStats, setShowStats] = useState(false)

	const [selectedAuthors, setSelectedAuthors] = useState<number[]>([])

	const [showSingularQuotes, setShowSingularQuotes] = useState(true)
	const [showDialogues, setShowDialogues] = useState(true)
	const [onlyStarred, setOnlyStarred] = useState(false)

	const [showSharing, setShowSharing] = useState(false)
	const [shareQuote, setShareQuote] = useState<IQuote | null>(null)
	const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)

	const quoteBag = [...quotes]

	const addSelectedAuthor = (authorId: number) => {
		setSelectedAuthors(Array.from(new Set([...selectedAuthors, authorId])))
	}

	const removeSelectedAuthor = (authorId: number) => {
		const authorIndex = selectedAuthors.findIndex((a) => a === authorId)
		if (authorIndex === -1) return

		setSelectedAuthors(selectedAuthors.toSpliced(authorIndex, 1))
	}

	useEffect(() => {
		setCanvas(document.getElementById("quoteCanvas") as HTMLCanvasElement)
	}, [])

	return (
		<>
			<canvas id="quoteCanvas" className="tw-hidden" width={1920} height={2560} />
			<ShareModal canvas={canvas} shareQuote={shareQuote} showSharing={showSharing} setShowSharing={setShowSharing} />

			<div className="tw-fixed tw-top-4 tw-right-4 tw-flex tw-flex-col">
				<button className="tw-text-gray-400 tw-text-xl hover:tw-text-gray-100 tw-mb-3" onClick={() => setShow(true)}>
					<i className="fas fa-filter" />
				</button>

				<button className="tw-text-gray-400 tw-text-xl hover:tw-text-gray-100" onClick={() => {
					const quoteIndex = Math.floor(Math.random() * quoteBag.length)
					setShareQuote(quoteBag[quoteIndex])

					quoteBag.splice(quoteIndex, 1)

					setShowSharing(true)
				}}>
					<i className="fas fa-dice" />
				</button>
			</div>

			<a className="tw-flex tw-items-center tw-justify-center tw-text-gray-400 tw-text-xl tw-rounded-full tw-bg-background-600 tw-w-[45px] tw-h-[45px] tw-fixed tw-bottom-6 tw-right-6 hover:tw-text-gray-100" href="#new">
				<i className="fas fa-chevron-down" />
			</a>

			<Modal centered size="lg" show={showStats} onHide={() => setShowStats(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Статистики</Modal.Title>
				</Modal.Header>
				<Modal.Body className="tw-p-6 tw-flex tw-flex-wrap tw-justify-around tw-gap-y-10">
					<div className="tw-w-full md:tw-w-1/2">
						<PieChart
							height={200}
							legend="right"
							data={[
								["Без Звездичка", quotes.filter((q) => !q.starred).length],
								["Със Звездичка", quotes.filter((q) => q.starred).length]
							]}
						/>
					</div>
					<div className="tw-w-full md:tw-w-1/2">
						<PieChart
							height={200}
							legend="right"
							data={[
								["Единични", quotes.filter((q) => q.content.length === 1).length],
								["Диалогови", quotes.filter((q) => q.content.length > 1).length]
							]}
						/>
					</div>
					<PieChart
						donut
						width="100%"
						height={250}
						legend="right"
						data={Object.entries<number>(
							quotes.reduce((acc, curr) => {
								curr.authors.forEach(({ authorId }) => {
									const author = authors.find((a) => a.authorId === authorId)?.name
									if (author) acc[author] ? (acc[author] += 1) : (acc[author] = 1)
								})

								return acc
							}, {})
						).filter(([k, v], i) => v >= 7)}
					/>
					<LineChart
						width="100%"
						legend="bottom"
						label="Нови цитати на ден"
						data={quotes
							.map((q) => q.date)
							.reduce((acc, curr) => {
								return curr ? (acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc) : acc
							}, {})}
					/>
					<LineChart
						width="100%"
						legend="bottom"
						label="Общо Цитати"
						data={quotes.reduce((acc, curr, i) => {
							return curr.date ? { ...acc, [curr.date]: i + 1 } : acc
						}, {})}
					/>
				</Modal.Body>
			</Modal>

			<Offcanvas className="tw-bg-background-500" show={show} placement="end" backdrop={false} scroll onHide={() => setShow(false)}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Филтриране</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="tw-mb-6">
						<h3 className="tw-font-bold tw-mb-2">По тип:</h3>
						<Form.Check label="Единичен Цитат" checked={showSingularQuotes} onChange={(e) => setShowSingularQuotes(Boolean(e.target.checked))} />
						<Form.Check label="Диалогов Цитат" checked={showDialogues} onChange={(e) => setShowDialogues(Boolean(e.target.checked))} />
						<Form.Switch className="tw-mt-2" label="Със Звездичка" checked={onlyStarred} onChange={(e) => setOnlyStarred(Boolean(e.target.checked))} />
					</div>
					<div>
						<h3 className="tw-font-bold tw-mb-2">По автор:</h3>
						{authors
							.sort((a, b) => a.name.localeCompare(b.name))
							.sort((a, b) => getAuthorQuotes(b.authorId).length - getAuthorQuotes(a.authorId).length)
							.map((a) => (
								<AuthorFilter author={a} isSelected={selectedAuthors.includes(a.authorId)} addSelectedAuthor={addSelectedAuthor} removeSelectedAuthor={removeSelectedAuthor} />
							))}
					</div>
				</Offcanvas.Body>
			</Offcanvas>

			<div className="tw-py-10 tw-flex tw-flex-col tw-items-center">
				<Container className="tw-mb-3">
					<div className="tw-px-2 sm:tw-px-6">
						<h1
							className="tw-inline-block tw-text-5xl tw-font-extrabold"
							style={{
								background: "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
								WebkitTextFillColor: "transparent",
								backgroundClip: "text"
							}}
						>
							Skizzicitati
						</h1>
						<div className="tw-text-lg tw-mt-2 tw-text-gray-200">
							<p className="tw-mb-3">Skizzicitati е колекция от забавни цитати и изцепки, които съм чул (или казал) и записал.</p>
							<p className="tw-mb-3">
								Колекцията расте постоянно от 2021 г. насам, като вмомента съдържа <strong>{quotes.length}</strong> цитата. Беше крайно време да се направи една добре изглеждаща страница, до която всеки има достъп, за да провери колко голям идиот е.
							</p>
						</div>
					</div>
				</Container>
				<Container className="tw-mb-3">
					<div className="tw-px-2 sm:tw-px-6 tw-text-lg tw-mt-2 tw-text-gray-200">
						<p className="tw-mb-3">
							Тук могат да се намерят два вида цитати - единични и диалогови. Диалоговите, както името подсказва, включват двама или повече автора. Цитати, които имат звездичка (<i className="fas fa-star" />) до снимката на автора са (според моя преценка) най-забавни и запомнящи се.
						</p>
						<p className="tw-mb-5">За ваше удобство, можете да натиснете върху името или профилната снимка на някой, за да видите колко цитати има определения човек и с какви други имена са записвани. Също така можете да филтрирате цитатите като използвате менюто, което можете да отворите с бутончето горе в дясно.</p>
						<button className="tw-font-bold tw-px-2 tw-py-1 tw-rounded" onClick={() => setShowStats(true)} style={{ background: "linear-gradient(45deg, rgba(131,58,180,0.75) 0%, rgba(253,29,29,0.75) 50%, rgba(252,176,69,0.75) 100%)" }}>
							<i className="fas fa-chart-pie" /> Статистики
						</button>
					</div>
				</Container>
				<Container key="asdjgaujhs">
					{quotes
						.filter((q) => ((showSingularQuotes || showDialogues) && !showSingularQuotes ? q.content.length > 1 : true))
						.filter((q) => ((showSingularQuotes || showDialogues) && !showDialogues ? q.content.length === 1 : true))
						.filter((q) => (onlyStarred ? q.starred : true))
						.filter((q) => (selectedAuthors.length ? q.authors.some((a) => selectedAuthors.includes(a.authorId)) : true))
						.map((q, i) => {
							const content: React.ReactNode[] = []

							if (quotes.findLastIndex((q) => q.newQuotes) === i && showSingularQuotes && showDialogues && !onlyStarred && selectedAuthors.length === 0) {
								content.push(
									<div id="new" className="tw-px-6 tw-my-2 tw-w-full tw-flex tw-items-center">
										<span key={i + "-2"} className="tw-relative tw-text-xs tw-font-bold tw-px-1 tw-rounded-md tw-bg-[red] after:tw-absolute after:tw-w-0 after:tw-h-0 after:tw-top-0 -after:tw-right-2 after:tw-border-8 after:tw-border-[transparent_transparent_transparent_red]">
											НОВИ
										</span>
										<hr className="tw-flex-grow tw-opacity-100 tw-text-[red] tw-rounded tw-border-[red]" />
									</div>
								)
							}

							content.push(<QuoteWrapper key={i} quote={q} canvas={canvas} setShowSharing={setShowSharing} setShareQuote={setShareQuote} />)

							return content
						})}
				</Container>
			</div>
		</>
	)
}

export default App
