import { IAuthor, IQuote } from "@/interfaces/Quote.interface"

import authors from "../data/authors.json"

export function resolveQuoteAuthors(quote: IQuote): IAuthor[] {
	return quote.authors.map(
		(a) =>
			authors.find((aa) => aa.authorId === a.authorId) ?? {
				authorId: a.authorId,
				name: "Непознат Автор"
			}
	)
}
