import React, { useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap"

import { drawText } from "canvas-txt"

import { IQuote } from "@/interfaces/Quote.interface"
import { resolveQuoteAuthors } from "../util/util"

function resolveText(quote: IQuote) {
	const quoteAuthors = resolveQuoteAuthors(quote)
	let text = ""

	if (quote.content.length === 1) {
		text += quote.content[0]
		text += "\n"
		text += "- " + (quote.authors[0].creditedAs ?? quoteAuthors[0].name)
	} else {
		quote.content.forEach((t, i) => {
			text += quote.authors[i].creditedAs ?? quoteAuthors[i].name
			text += ": "
			text += t
			text += "\n"
		})
	}

	return text
}

function resolveDate(quote: IQuote) {
	let text = ""

	if (quote.date) {
		const date = new Date(quote.date)

		text += date.getDate().toString().padStart(2, "0")
		text += "."
		text += (date.getMonth() + 1).toString().padStart(2, "0")
		text += "."
		text += date.getFullYear()
	}

	return text
}

export const ShareModal = React.memo(({ canvas, shareQuote, showSharing, setShowSharing }: { canvas: HTMLCanvasElement | null; shareQuote: IQuote | null; showSharing: boolean; setShowSharing: (val: boolean) => void }) => {
	const [image, setImage] = useState("")

	const generateImage = async () => {
		const ctx = canvas?.getContext("2d")

		const contentWidth = 1920
		const contentHeight = 1080

		const contentTop = ((canvas?.height ?? 1980) - 1080) / 2

		const imgSize = 400
		const imgRadius = 50

		const xQuotePadding = 256
		const yQuotePadding = contentTop + 125

		const xDatePadding = 64
		const yDatePadding = contentTop + 64

		const quoteFontSize = 60
		const dateFontSize = 48

		const bgColor = "#000"
		const quoteColor = "#fff"
		const dateColor = "#333"

		if (canvas && ctx && shareQuote) {
			ctx.reset()
			ctx.filter = "grayscale(1)"

			ctx.fillStyle = bgColor
			ctx.fillRect(0, 0, canvas.width, canvas.height)

			const authors = shareQuote.authors.filter((a, i) => !shareQuote.authors.some((aa, k) => aa.authorId === a.authorId && k < i))
			for (const a of authors) {
				const i = authors.indexOf(a)
				const order = authors.length - i

				const img = document.createElement("img")
				img.src = "/avatars/" + a.authorId + ".webp"
				img.onerror = (e: Event) => ((e.target as HTMLImageElement).src = "/avatars/default/" + ((shareQuote.authors[0].authorId * 1) % 5) + ".png")

				await new Promise((resolve) => {
					img.onload = () => {
						const imgX = contentWidth - imgSize * order
						const imgY = contentTop + contentHeight - imgSize

						ctx.save()

						if (i === 0) {
							ctx.beginPath()
							ctx.moveTo(imgX + imgRadius, imgY)
							ctx.lineTo(imgX + imgSize /*- imgRadius*/, imgY)
							// ctx.quadraticCurveTo(imgX + imgSize, imgY, imgX + imgSize, imgY + imgRadius)
							ctx.lineTo(imgX + imgSize, imgY + imgSize /*- imgRadius*/)
							// ctx.quadraticCurveTo(imgX + imgSize, imgY + imgSize, imgX + imgSize - imgRadius, imgY + imgSize)
							ctx.lineTo(imgX /*+ imgRadius*/, imgY + imgSize)
							// ctx.quadraticCurveTo(imgX, imgY + imgSize, imgX, imgY + imgSize - imgRadius)
							ctx.lineTo(imgX, imgY + imgRadius)
							ctx.quadraticCurveTo(imgX, imgY, imgX + imgRadius, imgY)
							ctx.closePath()
							ctx.clip()
						}

						ctx.drawImage(img, imgX, imgY, imgSize, imgSize)
						ctx.restore()

						resolve(null)
					}
				})
			}

			ctx.fillStyle = quoteColor
			drawText(ctx, resolveText(shareQuote), {
				x: xQuotePadding,
				y: yQuotePadding,
				width: canvas.width - xQuotePadding * 2,
				height: canvas.height - yQuotePadding * 2,

				align: "left",
				vAlign: "middle",

				fontSize: quoteFontSize,
				fontWeight: "bold"
			})

			ctx.fillStyle = dateColor
			drawText(ctx, resolveDate(shareQuote), {
				x: xDatePadding,
				y: yDatePadding,
				width: canvas.width - xDatePadding * 2,
				height: canvas.height - yDatePadding * 2,

				align: "right",
				vAlign: "top",

				fontSize: dateFontSize,
				fontWeight: "bold"
			})
		}
	}

	useEffect(() => {
		generateImage().then(() => setImage(canvas?.toDataURL("image/png") ?? ""))
	}, [shareQuote])

	return (
		<Modal size="lg" centered show={Boolean(showSharing)} onHide={() => setShowSharing(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Сподели Цитат</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="tw-overflow-hidden tw-w-full tw-flex tw-items-center tw-justify-center">{!image ? <Spinner /> : <img alt="Изображение на цитата" src={image} />}</div>
				{/*<Form.Group className="tw-mt-4">*/}
				{/*	<Form.Label className="tw-font-bold">Линк:</Form.Label>*/}
				{/*	<Form.Control readOnly value={window.location.origin + "#" + encodeURIComponent(shareQuote?.content[0] ?? "")} />*/}
				{/*</Form.Group>*/}
			</Modal.Body>
		</Modal>
	)
})
